import axios from 'axios';
import configs from "../../lib/config";

const apiBaseUrl = configs.apiBaseUrl;

// Set up Axios to include the token in the headers of all requests
axios.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        const isValid = true; // Replace with actual token validation logic
        if (isValid) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          localStorage.removeItem('token');
          window.location.href = '/portal/login'; // Redirect to login page
          throw new Error('Invalid token');
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

// Handle response errors
axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Clear all user-related data from localStorage
        localStorage.clear();
        window.location.href = '/portal/login'; // Redirect to login page
      }
      return Promise.reject(error);
    }
);
// Create a new admin
export const createAdmin = async (adminData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/admin`, adminData);
    return response.data;
  } catch (error) {
    console.error('Error creating admin:', error);
    throw error;
  }
};

// Get all admins
export const getAdmins = async () => {
  try {
    const response = await axios.get(`${apiBaseUrl}/admin`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching admins:', error);
    throw error;
  }
};

// Get admin by ID
export const getAdminById = async (adminId) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/admin/${adminId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching admin by ID:', error);
    throw error;
  }
};

// Update admin by ID
export const updateAdmin = async (adminId, adminData) => {
  try {
    const response = await axios.put(`${apiBaseUrl}/admin/${adminId}`, adminData);
    return response.data;
  } catch (error) {
    console.error('Error updating admin:', error);
    throw error;
  }
};

// Delete admin by ID
export const deleteAdmin = async (adminId) => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/admin/${adminId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting admin:', error);
    throw error;
  }
};

// AdminLogin admin
export const loginAdmin = async (loginData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/admin/login`, loginData);
    return response.data;
  } catch (error) {
    console.error('Error logging in admin:', error);
    throw error;
  }
};
