import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer style={{ textAlign: 'center' }}>
        ROW ©{new Date().getFullYear()} Developed and maintained by <a href="
        https://vertex.codes" target="_blank" rel="noopener noreferrer">Vertex</a>
    </Footer>
  );
};

export default AppFooter;
