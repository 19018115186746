import React, { useState, useEffect } from 'react';
                                                          import { Card, Spin, message, Button, Drawer, Tabs, Row, Col, Modal, Input, Form, Upload, Select } from 'antd';
                                                          import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined,
  EditOutlined,
                                                            WhatsAppOutlined
} from '@ant-design/icons';
                                                          import { getSchools, updateVerificationStatus, sendEmailFromAdmin } from '../../context/dbTransactions/school';
                                                          import { updateStorageFile } from '../../context/dbTransactions/storage';
                                                          import RegisterSchools from './RegisterSchools';

                                                          const { TabPane } = Tabs;
                                                          const { TextArea, Search } = Input;

                                                          const SchoolVerification = () => {
                                                            const [schools, setSchools] = useState([]);
                                                            const [filteredSchools, setFilteredSchools] = useState([]);
                                                            const [loading, setLoading] = useState(true);
                                                            const [drawerVisible, setDrawerVisible] = useState(false);
                                                            const [selectedSchool, setSelectedSchool] = useState(null);
                                                            const [rejectionReason, setRejectionReason] = useState('');
                                                            const [emailModalVisible, setEmailModalVisible] = useState(false);
                                                            const [emailForm] = Form.useForm();
                                                            const [emailLoading, setEmailLoading] = useState(false);
                                                            const [approveModalVisible, setApproveModalVisible] = useState(false);
                                                            const [approveForm] = Form.useForm();
                                                            const [uploading, setUploading] = useState(false);
                                                            const [documentURL, setDocumentURL] = useState('');
                                                            const [emailType, setEmailType] = useState('');

                                                            useEffect(() => {
                                                              const fetchPendingSchools = async () => {
                                                                try {
                                                                  const allSchools = await getSchools();
                                                                  setSchools(allSchools);
                                                                  setFilteredSchools(allSchools);
                                                                } catch (error) {
                                                                  message.error('Failed to fetch schools');
                                                                } finally {
                                                                  setLoading(false);
                                                                }
                                                              };

                                                              fetchPendingSchools();
                                                            }, []);

                                                            const handleView = (school) => {
                                                              setSelectedSchool(school);
                                                              setDrawerVisible(true);
                                                            };

                                                            const handleApprove = (school) => {
                                                              setSelectedSchool(school);
                                                              setApproveModalVisible(true);
                                                            };

                                                            const handleUpload = async ({ file }) => {
                                                              const folderName = `school_documents/${selectedSchool._id}`;
                                                              const fileKey = `${selectedSchool._id}_${approveForm.getFieldValue('documentName')}_${approveForm.getFieldValue('documentType')}.${file.type.split('/')[1]}`;
                                                              const fileType = file.type;

                                                              try {
                                                                setUploading(true);
                                                                const fileBuffer = await file.arrayBuffer();
                                                                const data = await updateStorageFile(folderName, fileKey, fileBuffer, fileType);
                                                                setDocumentURL(data.publicUrl);
                                                                message.success(`${file.name} uploaded successfully`);
                                                              } catch (error) {
                                                                message.error(`Failed to upload ${file.name}`);
                                                              } finally {
                                                                setUploading(false);
                                                              }
                                                            };

                                                            const handleSendEmail = () => {
                                                              emailForm.validateFields().then(async (values) => {
                                                                setEmailLoading(true);
                                                                const emailData = {
                                                                  email: values.email,
                                                                  type: values.type,
                                                                  reason: values.reason || '',
                                                                  title: values.title,
                                                                    lang: values.lang,
                                                                    message: values.message

                                                                };
                                                                try {
                                                                  await sendEmailFromAdmin(emailData);
                                                                  message.success('Email sent successfully.');
                                                                  setEmailModalVisible(false);
                                                                  emailForm.resetFields();
                                                                } catch (error) {
                                                                  message.error('Failed to send email.');
                                                                } finally {
                                                                  setEmailLoading(false);
                                                                }
                                                              }).catch((errorInfo) => {
                                                                console.error('Failed to send email:', errorInfo);
                                                              });
                                                            };

                                                            const handleApproveSubmit = async () => {
                                                              try {
                                                                const values = await approveForm.validateFields();
                                                                await updateVerificationStatus(selectedSchool._id, {
                                                                  verification_status: 'approved',
                                                                  documentURL,
                                                                  documentName: values.documentName,
                                                                  documentType: values.documentType
                                                                });
                                                                message.success('School approved');
                                                                setSchools(schools.filter(school => school._id !== selectedSchool._id));
                                                                setFilteredSchools(filteredSchools.filter(school => school._id !== selectedSchool._id));
                                                                setApproveModalVisible(false);
                                                              } catch (error) {
                                                                message.error('Failed to approve school');
                                                              }
                                                            };

                                                            const handleReject = (schoolId) => {
                                                              Modal.confirm({
                                                                title: 'Are you sure you want to reject this school?',
                                                                content: (
                                                                  <TextArea
                                                                    rows={4}
                                                                    placeholder="Please provide a reason for rejection"
                                                                    onChange={(e) => setRejectionReason(e.target.value)}
                                                                  />
                                                                ),
                                                                onOk: async () => {
                                                                  try {
                                                                    await updateVerificationStatus(schoolId, 'rejected', { reason: rejectionReason || 'No reason provided - Please Contact with ROW Support Team' });
                                                                    message.success('School rejected');
                                                                    setSchools(schools.filter(school => school._id !== schoolId));
                                                                    setFilteredSchools(filteredSchools.filter(school => school._id !== schoolId));
                                                                  } catch (error) {
                                                                    message.error('Failed to reject school');
                                                                  }
                                                                },
                                                              });
                                                            };

                                                            const handleEmailButtonClick = (school) => {
                                                              setSelectedSchool(school);
                                                              emailForm.setFieldsValue({ email: school.principal_email });
                                                              setEmailModalVisible(true);
                                                            };

                                                            const handleSearch = (value) => {
                                                              const filtered = schools.filter(school =>
                                                                school.school_name.toLowerCase().includes(value.toLowerCase()) ||
                                                                school.school_udias.toLowerCase().includes(value.toLowerCase()) ||
                                                                school.school_district.toLowerCase().includes(value.toLowerCase())
                                                              );
                                                              setFilteredSchools(filtered);
                                                            };

                                                            if (loading) {
                                                              return <Spin />;
                                                            }

                                                            const renderSchoolCard = (school) => (
                                                              <Col xs={24} sm={12} md={8} lg={6} key={school._id}>
                                                                <Card
                                                                  title={
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                      UDISE  {school.school_udias}
                                                                      <Button icon={<EditOutlined />} onClick={() => handleView(school)}></Button>
                                                                      <Button icon={<WhatsAppOutlined />} onClick={() => handleEmailButtonClick(school)}></Button>
                                                                      {/*<Button icon={<NotificationOutlined />} onClick={() => handleEmailButtonClick(school)} />*/}
                                                                    </div>
                                                                  }
                                                                  style={{ marginBottom: 16 }}
                                                                  actions={
                                                                    school.verification_status === 'pending' ? [
                                                                      <Button icon={<CheckOutlined />} type="primary" onClick={() => handleApprove(school)} style={{ width: 'fit-content' }}>Approve</Button>,
                                                                      <Button icon={<CloseOutlined />} danger onClick={() => handleReject(school._id)} style={{ width: 'fit-content' }}>Reject</Button>
                                                                    ] : school.verification_status === 'rejected' ? [
                                                                      <Button icon={<EditOutlined />} onClick={() => handleView(school)}> Edit </Button>
                                                                    ] : null
                                                                  }
                                                                >
                                                                  <p>School Name: {school.school_name}</p>
                                                                  <p>Year of Establishment: {school.year_establishment}</p>
                                                                  <p>District: {school.school_district}</p>
                                                                </Card>
                                                              </Col>
                                                            );

                                                            return (
                                                              <div>
                                                                <Search
                                                                  placeholder="Search by name, UDISE, or district"
                                                                  onSearch={handleSearch}
                                                                  style={{ marginBottom: 16, width: 300 }}
                                                                />
                                                                <Tabs defaultActiveKey="1">
                                                                  <TabPane tab="Pending" key="1">
                                                                    <Row gutter={[16, 16]}>
                                                                      {filteredSchools.filter(school => school.verification_status === 'pending').map(renderSchoolCard)}
                                                                    </Row>
                                                                  </TabPane>
                                                                  <TabPane tab="Rejected" key="2">
                                                                    <Row gutter={[16, 16]}>
                                                                      {filteredSchools.filter(school => school.verification_status === 'rejected').map(renderSchoolCard)}
                                                                    </Row>
                                                                  </TabPane>
                                                                </Tabs>

                                                                <Drawer
                                                                  title={selectedSchool?.school_name}
                                                                  visible={drawerVisible}
                                                                  onClose={() => setDrawerVisible(false)}
                                                                  width={600}
                                                                >
                                                                  {selectedSchool && (
                                                                    <RegisterSchools school={selectedSchool} />
                                                                  )}
                                                                </Drawer>

                                                                <Modal
                                                                  title={`Send Notification to ${selectedSchool?.school_name}`}
                                                                  visible={emailModalVisible}
                                                                  onCancel={() => setEmailModalVisible(false)}
                                                                  onOk={handleSendEmail}
                                                                  okText="Send"
                                                                >
                                                                  <Spin spinning={emailLoading}>
                                                                    <Form form={emailForm} layout="vertical">
                                                                      <Form.Item
                                                                          label="Email"
                                                                          name="email"
                                                                          rules={[{ required: true, message: 'Please input the email!' }]}
                                                                          initialValue={selectedSchool?.principal_email}
                                                                      >
                                                                        <Input />
                                                                      </Form.Item>
                                                                      <Form.Item
                                                                          label="Notification Type"
                                                                          name="type"
                                                                          rules={[{ required: true, message: 'Please select the notification type!' }]}
                                                                      >
                                                                        <Select onChange={(value) => setEmailType(value)}>
                                                                          <Select.Option value="welcome">Welcome</Select.Option>
                                                                          <Select.Option value="schoolRejection">School Rejection</Select.Option>
                                                                          <Select.Option value="custom">Custom</Select.Option>
                                                                        </Select>
                                                                      </Form.Item>
                                                                      {emailType === 'schoolRejection' && (
                                                                          <Form.Item
                                                                              label="Reason (if applicable)"
                                                                              name="reason"
                                                                          >
                                                                            <Input.TextArea />
                                                                          </Form.Item>
                                                                      )}
                                                                      {emailType === 'custom' && (
                                                                          <>
                                                                            <Form.Item
                                                                                label="Language"
                                                                                name="lang"
                                                                                rules={[{ required: true, message: 'Please select the language!' }]}
                                                                            >
                                                                              <Select>
                                                                                <Select.Option value="en">English</Select.Option>
                                                                                <Select.Option value="mr">Marathi</Select.Option>
                                                                              </Select>
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                label="Title"
                                                                                name="title"
                                                                                rules={[{ required: true, message: 'Please input the title!' }]}
                                                                            >
                                                                              <Input />
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                label="Message"
                                                                                name="message"
                                                                                rules={[{ required: true, message: 'Please input the message!' }]}
                                                                            >
                                                                              <Input.TextArea />
                                                                            </Form.Item>

                                                                          </>
                                                                      )}

                                                                    </Form>
                                                                  </Spin>
                                                                </Modal>

                                                                <Modal
                                                                  title="Approve School"
                                                                  visible={approveModalVisible}
                                                                  onCancel={() => setApproveModalVisible(false)}
                                                                  onOk={handleApproveSubmit}
                                                                  okButtonProps={{ disabled: !documentURL }}
                                                                  okText="Approve"
                                                                >
                                                                  <Spin spinning={uploading}>
                                                                    <Form form={approveForm} layout="vertical">
                                                                      <Form.Item
                                                                        label="Document Name"
                                                                        name="documentName"
                                                                        rules={[{ required: true, message: 'Please input the document name!' }]}
                                                                        initialValue="offline_registration"
                                                                      >
                                                                        <Input readOnly />
                                                                      </Form.Item>
                                                                      <Form.Item
                                                                        label="Document Type"
                                                                        name="documentType"
                                                                        rules={[{ required: true, message: 'Please select the document type!' }]}
                                                                      >
                                                                        <Select>
                                                                          <Select.Option value="DeliveryChallan">Delivery Challan</Select.Option>
                                                                          <Select.Option value="Other">Other</Select.Option>
                                                                        </Select>
                                                                      </Form.Item>
                                                                      <Form.Item
                                                                        label="Upload Document"
                                                                        name="document"
                                                                        rules={[{ required: true, message: 'Please upload the document!' }]}
                                                                      >
                                                                        <Upload
                                                                          name="document"
                                                                          listType="picture"
                                                                          maxCount={1}
                                                                          beforeUpload={(file) => {
                                                                            handleUpload({ file });
                                                                            return false; // Prevent automatic upload
                                                                          }}
                                                                        >
                                                                          <Button icon={<UploadOutlined />}>Click to upload</Button>
                                                                        </Upload>
                                                                      </Form.Item>
                                                                    </Form>
                                                                  </Spin>
                                                                </Modal>
                                                              </div>
                                                            );
                                                          };

                                                          export default SchoolVerification;
