import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
// import { loginUser } from '../../context/auth/userLogin';
import { useNavigate } from 'react-router-dom';
import './SchoolLogin.css';
import {loginSchool} from "../../context/dbTransactions/school";

const SchoolLogin = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await loginSchool(values);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userRole', response.data.role);

      message.success('Login successful!');
      if (response.data.role === 'admin') {
        navigate('/portal/login');
      } else if (response.data.role === 'principal') {
        navigate('/school-admin/dashboard');
      }
    } catch (error) {
      message.error('Login failed. Please check your credentials.');
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div className="login-container">
      <Card title="School Login" className="login-box">
        <Form
          name="login"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your Email or UDIAS!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default SchoolLogin;
