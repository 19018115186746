import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import ChatList from "../components/whatsaap/ChatList";
import ChatBox from "../components/whatsaap/ChatBox";
import { getConversations } from "../context/dbTransactions/conversations";

const { Content, Sider } = Layout;

const Whatsaap = () => {
    const [selectedChat, setSelectedChat] = useState(null);
    const [chatData, setChatData] = useState([]);

    useEffect(() => {
        const fetchConversations = async () => {
            try {
                const response = await getConversations();

                if (response?.data && Array.isArray(response.data)) {
                    setChatData(response.data); // Correctly setting chat data
                } else {
                    console.error("Invalid response structure:", response);
                }
            } catch (error) {
                console.error("Error fetching conversations:", error);
            }
        };

        fetchConversations();
    }, []);

    return (
        <Layout style={{ height: "100vh" }}>
            {/* Sidebar - Chat List */}
            <Sider width={300} style={{ background: "#fff", padding: "10px" }}>
                <ChatList chats={chatData} onSelectChat={setSelectedChat} />
            </Sider>

            {/* Main Content - Chat Box */}
            <Content style={{ padding: "10px" }}>
                <ChatBox selectedChat={selectedChat} />
            </Content>
        </Layout>
    );
};

export default Whatsaap;
