import React from 'react';
import SchoolLogin from "../components/auth/SchoolLogin";

function SchoolLoginPage() {
  return (
    <div className="App">
      <SchoolLogin />
    </div>
  );
}

export default SchoolLoginPage;
