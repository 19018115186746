import React, { useState, useEffect } from 'react';
                                import { Form, Input, Button, Steps, Row, Col, message, Upload, Select, Modal, Checkbox } from 'antd';
                                import { UploadOutlined } from '@ant-design/icons';
                                import { getCities, getStates } from "../utils/StateCitydataUtils";
                                import { updateStorageFile } from '../context/dbTransactions/storage';
import { createSchool } from "../context/dbTransactions/school";
                                import { useNavigate } from 'react-router-dom';

                                const { Step } = Steps;
                                const { Option } = Select;

                                const states = getStates();
                                const cities = getCities();

                                const select1 = ["Yes", "Boys Only", "Girls Only"];
                                const select2 = ["PhD", "Post Graduate", "Graduate", "Others"];
                                const select3 = ["Science (PCB)", "Commerce", "Arts", "Maths", "Computer Science", "Others"];
                                const select4 = ["CBSE", "ICSE / ISC", "State Board"];
                                const select5 = ["Central Government (CBSE)", "State Government", "Local Body (Municipality / Nagar Nigam)", "Private Aided", "Private Unaided", "Others"];

                                const SchoolRegistrationPage = () => {
                                    const [current, setCurrent] = useState(0);
                                    const [selectedState, setSelectedState] = useState('');
                                    const [cityOptions, setCityOptions] = useState([]);
                                    const [fileList, setFileList] = useState([]);
                                    const [form] = Form.useForm();
                                    const [isModalVisible, setIsModalVisible] = useState(false);
                                    const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);
                                    const [loading, setLoading] = useState(false);
                                    const navigate = useNavigate();

                                    useEffect(() => {
                                        if (selectedState) {
                                            setCityOptions(cities[selectedState] || []);
                                        } else {
                                            setCityOptions([]);
                                        }
                                    }, [selectedState]);

                                    const handleUpload = async ({ file }) => {
                                        const folderName = 'school_logos';
                                        const fileKey = file.name;
                                        const fileType = file.type;

                                        try {
                                            const fileBuffer = await file.arrayBuffer();
                                            const data = await updateStorageFile(folderName, fileKey, fileBuffer, fileType);
                                            console.log(data.publicUrl);
                                            form.setFieldsValue({ school_logo: data.publicUrl });
                                            message.success(`${file.name} uploaded successfully`);
                                        } catch (error) {
                                            message.error(`Failed to upload ${file.name}`);
                                        }
                                    };

                                    const steps = [
                                        {
                                            title: 'School Details',
                                            content: (
                                                <Form form={form} layout="vertical">
                                                    <Form.Item label="School Name" name="school_name" rules={[{ required: true, message: 'Please input the school name!' }]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="UDISE" name="school_udias" rules={[{ required: true, message: 'Please input the UDISE!' }]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Address" name="school_address" rules={[{ required: true, message: 'Please input the address!' }]}>
                                                        <Input.TextArea />
                                                    </Form.Item>
                                                    <Form.Item label="State" name="school_state" rules={[{ required: true, message: 'Please select the state!' }]}>
                                                        <Select onChange={(value) => setSelectedState(value)}>
                                                            {states.map((state) => (
                                                                <Option key={state.code} value={state.value}>
                                                                    {state.value}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item label="District" name="school_city" rules={[{ required: true, message: 'Please select the city!' }]}>
                                                        <Select disabled={!selectedState}>
                                                            {cityOptions.map((city) => (
                                                                <Option key={city} value={city}>
                                                                    {city}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Sub - District" name="school_district" rules={[{ required: true, message: 'Please enter the district!' }]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Pin/Zip Code" name="pin_zip_code" rules={[{ required: true, message: 'Please input the pin/zip code!' }]}>
                                                        <Input type="number" />
                                                    </Form.Item>

                                                    <Form.Item label="School Logo" name="school_logo" rules={[{ required: false, message: 'Please upload the school logo!' }]}>
                                                        <Upload
                                                            name="logo"
                                                            listType="picture"
                                                            maxCount={1}
                                                            fileList={fileList}
                                                            beforeUpload={(file) => {
                                                                setFileList([file]);
                                                                handleUpload({ file });
                                                                return false; // Prevent automatic upload
                                                            }}
                                                            onRemove={() => setFileList([])}
                                                        >
                                                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                    <Form.Item name="opt_in_notifications" valuePropName="checked">
                                                        <Checkbox>I want to receive notifications and updates</Checkbox>
                                                    </Form.Item>
                                                </Form>
                                            ),
                                        },
                                        {
                                            title: 'Principal Details',
                                            content: (
                                                <Form form={form} layout="vertical">
                                                    <Form.Item label="Principal Name" name="principal_name" rules={[{ required: true, message: 'Please input the principal name!' }]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Principal Email" name="principal_email" rules={[{ required: true, type:"email",message: 'Please input the principal email!' }]}>
                                                        <Input type="email" />
                                                    </Form.Item>
                                                    <Form.Item label="Principal Phone" name="principal_phone" rules={[{ required: true, message: 'Please input the principal phone!' }]}>
                                                        <Input type="number" />
                                                    </Form.Item>
                                                    <Form.Item label="Highest Qualification" name="principal_highest_qualification" rules={[{ required: true, message: 'Please select the highest qualification!' }]}>
                                                        <Select>
                                                            {select2.map((item) => (
                                                                <Option key={item} value={item}>
                                                                    {item}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Stream Qualification" name="principal_stream_qualification" rules={[{ required: true, message: 'Please select the stream qualification!' }]}>
                                                        <Select>
                                                            {select3.map((item) => (
                                                                <Option key={item} value={item}>
                                                                    {item}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Form>
                                            ),
                                        },
                                        {
                                            title: 'Additional Details',
                                            content: (
                                                <Form form={form} layout="vertical">
                                                    <Form.Item label="Year of Establishment" name="year_establishment"  rules={[{ message: 'Please input the year of establishment!' }]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="School Type" name="school_type" rules={[{required: true, message: 'Please select the school type!' }]}>
                                                        <Select>
                                                            {select5.map((item) => (
                                                                <Option key={item} value={item}>
                                                                    {item}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Board Affiliation" name="board_affiliation" rules={[{ required: true, message: 'Please select the board affiliation!' }]}>
                                                        <Select>
                                                            {select4.map((item) => (
                                                                <Option key={item} value={item}>
                                                                    {item}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Co-Educational" name="co_educational" rules={[{ required: true, message: 'Please select the co-educational status!' }]}>
                                                        <Select>
                                                            {select1.map((item) => (
                                                                <Option key={item} value={item}>
                                                                    {item}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Total Teachers" name="total_teachers" rules={[{   message: 'Please input the total number of teachers!' }]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Total Students" name="total_students" rules={[{   message: 'Please input the total number of students!' }]}>
                                                        <Input />
                                                    </Form.Item>
                                                    {/*<Form.Item label="Total Enrollment (VI to X)" name="total_enrollment_vi_to_x" rules={[{  message: 'Please input the total enrollment!' }]}>*/}
                                                    {/*    <Input />*/}
                                                    {/*</Form.Item>*/}
                                                </Form>
                                            ),
                                        },
                                    ];

                                    const next = async () => {
                                        try {
                                            await form.validateFields();
                                            setCurrent(current + 1);
                                        } catch (error) {
                                            console.error('Validation failed:', error);
                                        }
                                    };

                                    const prev = () => {
                                        setCurrent(current - 1);
                                    };

                                    const handleSubmit = async () => {
                                        try {
                                            setLoading(true);
                                            const values = await form.getFieldsValue(true);
                                            console.log('Received values:', values);
                                            await createSchool(values);
                                            message.success('School registered successfully!');
                                            setIsModalVisible(true);
                                        } catch (error) {
                                            console.error('Failed:', error.response.data.message);
                                            if (error.response.data.message.includes('E11000 duplicate key error collection')) {
                                                message.error('School is already registered.');
                                                setIsDuplicateModalVisible(true);
                                            } else {
                                                message.error('Failed to submit school.');
                                                console.error('Failed:', error);
                                            }
                                        } finally {
                                            setLoading(false);
                                        }
                                    };

                                    const handleModalOk = () => {
                                        setIsModalVisible(false);
                                        navigate('/login');
                                    };

                                    const handleModalCancel = () => {
                                        setIsModalVisible(false);
                                        navigate('/school-registration');
                                    };

                                    const handleDuplicateModalOk = () => {
                                        setIsDuplicateModalVisible(false);
                                        window.location.reload();
                                        navigate('/school-registration');
                                    };

                                    return (
                                        <div style={{ padding: '24px' }} >
                                            <img src="/assets/robonist.svg" alt="row-logo" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '10%' }} className="logo-img" />
                                            <h1 style={{textAlign:'center', margin:'15px'}}>ROW - School Registration</h1>
                                            <Row justify="center">
                                                <Col xs={24} sm={20} md={16} lg={12}>
                                                    <Steps current={current}>
                                                        {steps.map((item) => (
                                                            <Step key={item.title} title={item.title} />
                                                        ))}
                                                    </Steps>
                                                    <div className="steps-content" style={{ marginTop: '24px' }}>
                                                        {steps[current].content}
                                                    </div>
                                                    <div className="steps-action" style={{ marginTop: '24px', textAlign: 'right' }}>
                                                        {current > 0 && (
                                                            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                                                Previous
                                                            </Button>
                                                        )}
                                                        {current < steps.length - 1 && (
                                                            <Button type="primary" onClick={() => next()}>
                                                                Next
                                                            </Button>
                                                        )}
                                                        {current === steps.length - 1 && (
                                                            <Button type="primary" onClick={() => handleSubmit()} loading={loading}>
                                                                Submit
                                                            </Button>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Modal
                                                title="Registration Successful"
                                                visible={isModalVisible}
                                                onOk={handleModalOk}
                                                onCancel={handleModalCancel}
                                            >
                                                <p>Your school has been registered successfully! Your registration is still under review. Once the team reviews and approves your registration, you'll receive an email confirmation with your login ID and password.</p>
                                            </Modal>
                                            <Modal
                                                title="Duplicate Registration"
                                                visible={isDuplicateModalVisible}
                                                onOk={handleDuplicateModalOk}
                                                onCancel={handleDuplicateModalOk}
                                            >
                                                <p>The school is already registered. Please check the details and try again.</p>
                                            </Modal>
                                        </div>
                                    );
                                };

                                export default SchoolRegistrationPage;
