import React, { useState, useEffect } from 'react';
                           import { Card, Table, Button, Drawer, message, Modal, Input, Form, Checkbox, Spin } from 'antd';
                           import { EditOutlined, DeleteOutlined, SettingOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
                           import RegisterSchools from '../components/school/RegisterSchools';
                           import ManageSchool from '../components/school/ManageSchool';
                           import { getSchools, deleteSchool, sendHTMLEmail, sendPasswordEmailByAdmin } from '../context/dbTransactions/school';
                           import ReactQuill from 'react-quill';
                           import 'react-quill/dist/quill.snow.css';

                           const { Search } = Input;

                           const School = () => {
                             const [drawerVisible, setDrawerVisible] = useState(false);
                             const [schools, setSchools] = useState([]);
                             const [filteredSchools, setFilteredSchools] = useState([]);
                             const [editingSchool, setEditingSchool] = useState(null);
                             const [managingSchool, setManagingSchool] = useState(null);
                             const [emailModalVisible, setEmailModalVisible] = useState(false);
                             const [emailForm] = Form.useForm();
                             const [loading, setLoading] = useState(false);
                             const [selectedSchoolEmail, setSelectedSchoolEmail] = useState('');

                             useEffect(() => {
                               const fetchSchools = async () => {
                                 try {
                                   const data = await getSchools();
                                   const approvedSchools = data.filter(school => school.verification_status === 'approved');
                                   setSchools(approvedSchools);
                                   setFilteredSchools(approvedSchools);
                                 } catch (error) {
                                   message.error('Failed to fetch schools.');
                                   console.error('Error fetching schools:', error);
                                 }
                               };

                               fetchSchools();
                             }, []);

                             const showDrawer = (school = null) => {
                               setEditingSchool(school);
                               setDrawerVisible(true);
                             };

                             const closeDrawer = () => {
                               setDrawerVisible(false);
                               setEditingSchool(null);
                               setManagingSchool(null);
                             };

                             const handleEdit = (record) => {
                               showDrawer(record);
                             };

                             const confirmDelete = (schoolId) => {
                               Modal.confirm({
                                 title: 'Are you sure you want to delete this school?',
                                 onOk: () => handleDelete(schoolId),
                               });
                             };

                             const handleDelete = async (schoolId) => {
                               try {
                                 await deleteSchool(schoolId);
                                 setSchools(schools.filter(school => school.uid !== schoolId));
                                 setFilteredSchools(filteredSchools.filter(school => school.uid !== schoolId));
                                 message.success('School deleted successfully.');
                               } catch (error) {
                                 message.error('Failed to delete school.');
                                 console.error('Error deleting school:', error);
                               }
                             };

                             const handleManage = (record) => {
                               setManagingSchool(record);
                               setDrawerVisible(true);
                             };

                             const handleSearch = (value) => {
                               const filtered = schools.filter(school =>
                                 school.school_name.toLowerCase().includes(value.toLowerCase()) ||
                                 school.school_udias.toLowerCase().includes(value.toLowerCase()) ||
                                 school.school_district.toLowerCase().includes(value.toLowerCase())
                               );
                               setFilteredSchools(filtered);
                             };

                             const handleSendEmail = () => {
                               emailForm.validateFields().then(async values => {
                                 setLoading(true);
                                 const emailData = {
                                   email: selectedSchoolEmail,
                                   subject: values.subject,
                                   htmlMessage: values.message,
                                   sendSms: values.sendSms,
                                 };
                                 try {
                                   await sendHTMLEmail(emailData);
                                   message.success('Email sent successfully.');
                                   setEmailModalVisible(false);
                                   emailForm.resetFields();
                                 } catch (error) {
                                   message.error('Failed to send email.');
                                   console.error('Failed to send email:', error);
                                 } finally {
                                   setLoading(false);
                                 }
                               }).catch(errorInfo => {
                                 console.error('Failed to send email:', errorInfo);
                               });
                             };

                             const handleSendPasswordEmail = async (email, phone) => {
                               try {
                                 await sendPasswordEmailByAdmin(email, phone);
                                 message.success('Password email sent successfully.');
                               } catch (error) {
                                 message.error('Failed to send password email.');
                                 console.error('Failed to send password email:', error);
                               }
                             };

                             const columns = [
                               {
                                 title: 'School Name',
                                 dataIndex: 'school_name',
                                 key: 'school_name',
                               },
                               {
                                 title: 'UDIAS',
                                 dataIndex: 'school_udias',
                                 key: 'school_udias',
                               },
                               {
                                 title: 'Phase Status',
                                 dataIndex: 'phaseStatus',
                                 key: 'phaseStatus',
                                 filters: [
                                   { text: 'Foundation', value: 'Foundation' },
                                   { text: 'Intermediate', value: 'Intermediate' },
                                   { text: 'Advance', value: 'Advance' },
                                 ],
                                 onFilter: (value, record) => record.phaseStatus === value,
                               },
                               {
                                 title: 'Kit Delivery Status',
                                 dataIndex: 'kitDeliveryStatus',
                                 key: 'kitDeliveryStatus',
                                 filters: [
                                   { text: 'Pending', value: 'Pending' },
                                   { text: 'Delivered', value: 'Delivered' },
                                 ],
                                 onFilter: (value, record) => record.kitDeliveryStatus === value,
                               },
                               {
                                 title: 'District',
                                 dataIndex: 'school_district',
                                 key: 'school_district',
                               },
                               {
                                 title: 'Actions',
                                 key: 'actions',
                                 render: (text, record) => (
                                   <span>
                                     <Button icon={<EditOutlined />} style={{ marginRight: 8 }} onClick={() => handleEdit(record)} />
                                     <Button icon={<DeleteOutlined />} style={{ marginRight: 8 }} onClick={() => confirmDelete(record._id)} />
                                     <Button icon={<SettingOutlined />} style={{ marginRight: 8 }} onClick={() => handleManage(record)} />
                                     <Button icon={<MailOutlined />} style={{ marginRight: 8 }} onClick={() => {
                                       setSelectedSchoolEmail(record.principal_email);
                                       setEmailModalVisible(true);
                                     }} />
                                     <Button icon={<LockOutlined />} onClick={() => handleSendPasswordEmail(record.principal_email, record.principal_phone)} />
                                   </span>
                                 ),
                               },
                             ];

                             return (
                               <div>
                                 <Card
                                   title="Schools"
                                   extra={<Search placeholder="Search by name, UDIAS, or district" onSearch={handleSearch} style={{ width: 300 }} />}
                                 >
                                   <Table columns={columns} dataSource={filteredSchools} rowKey="uid" />
                                 </Card>
                                 <Drawer
                                   title={editingSchool ? "Edit School" : managingSchool ? "Manage School" : "Register School"}
                                   width={720}
                                   onClose={closeDrawer}
                                   visible={drawerVisible}
                                   bodyStyle={{ paddingBottom: 80 }}
                                 >
                                   {editingSchool ? (
                                     <RegisterSchools school={editingSchool} />
                                   ) : managingSchool ? (
                                     <ManageSchool school={managingSchool} onClose={closeDrawer} />
                                   ) : (
                                     <RegisterSchools />
                                   )}
                                 </Drawer>
                                 <Modal
                                   title={`Send Email to ${selectedSchoolEmail}`}
                                   visible={emailModalVisible}
                                   onCancel={() => setEmailModalVisible(false)}
                                   onOk={handleSendEmail}
                                   okText="Send"
                                 >
                                   <Spin spinning={loading}>
                                     <Form form={emailForm} layout="vertical">
                                       <Form.Item
                                         label="Subject"
                                         name="subject"
                                         rules={[{ required: true, message: 'Please input the email subject!' }]}
                                       >
                                         <Input />
                                       </Form.Item>
                                       <Form.Item
                                         label="Message"
                                         name="message"
                                         rules={[{ required: true, message: 'Please input the email message!' }]}
                                       >
                                         <ReactQuill theme="snow" />
                                       </Form.Item>
                                       <Form.Item
                                         name="sendSms"
                                         valuePropName="checked"
                                       >
                                         <Checkbox>Send SMS + EMAIL</Checkbox>
                                       </Form.Item>
                                     </Form>
                                   </Spin>
                                 </Modal>
                               </div>
                             );
                           };

                           export default School;
