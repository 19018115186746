import React from 'react';
import Hero from "../components/landing/Hero";
import Intro from "../components/landing/Intro";
import Services from "../components/landing/Services";
import Portfolio from "../components/landing/Portfolio";
import Footer from "../components/landing/Footer";


  const LandingPage = () => {

    return (
        <>
          <Hero />
          <Intro />
          <Services />
          <Portfolio />
          {/*<Clients />*/}
          {/*<Cta/>*/}
          <Footer />
        </>
    );
  };

  export default LandingPage;
