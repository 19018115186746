import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Checkbox, Card, message, Upload } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createProject, updateProject } from '../../context/dbTransactions/projects';
import { updateStorageFile } from '../../context/dbTransactions/storage';
import { UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { Option } = Select;

const projectPhases = ['1', '2', '3'];
const projectTypes = ['video', 'text'];
const projectStatuses = ['Pending', 'Approved', 'Rejected'];

const AddProjects = ({ project, onClose }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (project) {
      // eslint-disable-next-line no-unused-vars
      const { projectThumbnail, ...rest } = project; // Remove projectThumbnail
      form.setFieldsValue(rest);
    } else {
      form.resetFields();
    }
  }, [project, form]);

  const handleUpload = async ({ file }) => {
    const folderName = 'project_thumbnails';
    const fileKey = file.name;
    const fileType = file.type;

    try {
      const fileBuffer = await file.arrayBuffer();
      const data = await updateStorageFile(folderName, fileKey, fileBuffer, fileType);
      form.setFieldsValue({ projectThumbnail: data.publicUrl });
      message.success(`${file.name} uploaded successfully`);
    } catch (error) {
      message.error(`Failed to upload ${file.name}`);
    }
  };

  const onFinish = async (values) => {
    try {
      if (project) {
        await updateProject(project._id, values);
        message.success('Project updated successfully.');
        onClose();
      } else {
        await createProject(values);
        message.success('Project created successfully.');
        onClose();
      }
    } catch (error) {
      message.error('Failed to save project.');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Card title={project ? "Edit Project Details" : "Add Project Details"} style={{ maxWidth: 600, margin: 'auto' }}>
      <Form
        form={form}
        name="add_project"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Project Code"
          name="code"
          rules={[{ required: true, message: 'Please input the project code!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Project Title"
          name="name"
          rules={[{ required: true, message: 'Please input the project title!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Duration (in Min)"
          name="duration"
          rules={[{ required: true, message: 'Please input the duration!' }]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="Phase"
          name="phase"
          rules={[{ required: true, message: 'Please select the phase!' }]}
        >
          <Select>
            {projectPhases.map((phase) => (
              <Option key={phase} value={phase}>
                {phase}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="isSubmissionProject"
          valuePropName="checked"
        >
          <Checkbox>Is Training Module</Checkbox>
        </Form.Item>

        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, message: 'Please select the type!' }]}
        >
          <Select>
            {projectTypes.map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true, message: 'Please select the status!' }]}
        >
          <Select>
            {projectStatuses.map((status) => (
              <Option key={status} value={status}>
                {status}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: 'Please input the description!' }]}
        >
          <ReactQuill theme="snow" />
        </Form.Item>

        <Form.Item
          label="Project Thumbnail"
          name="projectThumbnail"
          rules={[{ required: true, message: 'Please upload the project thumbnail!' }]}
        >
          <Upload
            name="thumbnail"
            listType="picture"
            maxCount={1}
            fileList={fileList}
            beforeUpload={(file) => {
              setFileList([file]);
              handleUpload({ file });
              return false; // Prevent automatic upload
            }}
            onRemove={() => setFileList([])}
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Project Video Link"
          name="projectVideoLink"
          dependencies={['type']}
          rules={[
            () => ({
              message: 'Please input the project video link!',
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Project Link"
          name="projectLink"
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

AddProjects.propTypes = {
  project: PropTypes.shape({
    _id: PropTypes.string,
    projectThumbnail: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    duration: PropTypes.number,
    phase: PropTypes.string,
    isSubmissionProject: PropTypes.bool,
    type: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
    projectVideoLink: PropTypes.string,
    projectLink: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
};

export default AddProjects;
