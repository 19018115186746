import React from 'react';
import { Card, Button, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';

const GetStart = () => {
    const navigate = useNavigate();

    const handleLogin = (path) => {
        navigate(path);
    };

    return (
        <Row justify="center" style={{ minHeight: '100vh', alignItems: 'center' }}>
            <Col>
                <img src="/assets/logo.png" alt="Logo" style={{ height: '100px', display: 'block', margin: 'auto' }} />

                <Card title="Welcome to the Robotics on Wheels Portal." style={{ maxWidth: 600, textAlign: 'center' }}>
                    <p> This is a platform for schools to manage their projects and submissions.</p>
                    <p>To get started, please login using one of the options below.</p>
                    {/*<Button type="primary" onClick={() => handleLogin('/portal/login')} style={{ margin: '10px' }}>*/}
                    {/*  Login as Admin*/}
                    {/*</Button>*/}
                    {/*<Button type="primary" onClick={() => handleLogin('/portal/login')} style={{ margin: '10px' }}>*/}
                    {/*  Login as Admin*/}
                    {/*</Button>*/}
                    <Button type="default" onClick={() => handleLogin('/school-registration')} style={{ margin: '10px' }}>
                        Register Your School
                    </Button>
                    <Button type="primary" onClick={() => handleLogin('/login')} style={{ margin: '10px' }}>
                        Login as School Admin
                    </Button>

                </Card>
            </Col>
        </Row>
    );
};

export default GetStart;
