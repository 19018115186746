import axios from 'axios';
import configs from "../../lib/config";

const apiBaseUrl = configs.apiBaseUrl;
// Set up Axios to include the token in the headers of all requests
axios.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        const isValid = true; // Replace with actual token validation logic
        if (isValid) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          localStorage.removeItem('token');
          window.location.href = '/portal/login'; // Redirect to login page
          throw new Error('Invalid token');
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

// Handle response errors
axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Clear all user-related data from localStorage
        localStorage.clear();
        window.location.href = '/portal/login'; // Redirect to login page
      }
      return Promise.reject(error);
    }
);
// Create a new project
export const createProject = async (projectData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/projects`, projectData);
    return response.data;
  } catch (error) {
    console.error('Error creating project:', error);
    throw error;
  }
};

// Get all projects
export const getProjects = async () => {
  try {
    const response = await axios.get(`${apiBaseUrl}/projects`);
    return response.data.data;
  } catch (error) {
    console.error('Error getting projects:', error);
    throw error;
  }
};

// Get project by ID
export const getProjectById = async (projectId) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting project by ID:', error);
    throw error;
  }
};

// Update project by ID
export const updateProject = async (projectId, projectData) => {
  try {
    const response = await axios.put(`${apiBaseUrl}/projects/${projectId}`, projectData);
    return response.data;
  } catch (error) {
    console.error('Error updating project:', error);
    throw error;
  }
};

// Delete project by ID
export const deleteProject = async (projectId) => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting project:', error);
    throw error;
  }
};
