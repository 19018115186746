import React from 'react';


                                    const cardData = [
                                        {
                                            img: '/assets/images/1 92.svg',
                                            title: "Bridge the Technology Gap",
                                            description: "Bringing state-of-the-art robotics education to underserved communities."
                                        },
                                        {
                                            img: '/assets/images/4 1.svg',
                                            title: "Encourage Innovation",
                                            description: "Providing hands-on training to spark creativity and problem-solving skills."
                                        },
                                        {
                                            img: '/assets/images/3 629608.svg',
                                            title: "Make STEM Accessible",
                                            description: "Ensuring every child, regardless of background, has access to quality education."
                                        },
                                        {
                                            img: '/assets/images/Illustration.svg',
                                            title: "Prepare for the Future",
                                            description: "Equipping students with skills to compete in global robotics competitions and future careers."
                                        }
                                    ];

                                    const Services = () => {
                                        return (
                                            <div id="services" className="bg-light py-12">
                                                <section data-aos="zoom-in-down">
                                                    <div className="my-4 py-4">
                                                        <h2 className="my-2 text-center text-3xl text-black uppercase font-bold">Our Mission</h2>
                                                        <div className='flex justify-center'>
                                                            <div className='w-24 border-b-4 text-lightBlack'></div>
                                                        </div>
                                                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-light text-lightBlack">
                                                            We are deeply committed to the growth and success of our clients.
                                                        </h2>
                                                    </div>

                                                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                                                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                                                            {cardData.map((card, index) => (
                                                                <div key={index} className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-black  rounded-lg shadow-sm p-6 group">
                                                                    <div className="m-2 text-center text-sm">
                                                                        <img alt="card img" className="rounded-t group-hover:scale-110 transition duration-1000 ease-in-out" src={card.img} />
                                                                        <h2 className="font-normal my-4 text-xl text-center">{card.title}</h2>
                                                                        <p className=" font-light">
                                                                            {card.description}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        )
                                    }

                                    export default Services;
