// src/components/school-admin/SchoolAdminSidebar.js
    import React from 'react';
    import { Layout, Menu } from 'antd';
    import {  BookOutlined,  DashboardOutlined, LogoutOutlined, ProjectOutlined } from '@ant-design/icons';
    import { useNavigate } from 'react-router-dom';
    import Cookies from 'js-cookie';

    const { Sider } = Layout;

    const SchoolAdminSidebar = () => {
      const navigate = useNavigate();

      const handleMenuClick = (e) => {
        switch (e.key) {
          case 'dashboard':
            navigate('/school-admin/dashboard');
            break;
          case 'schools':
            navigate('/school-admin/schools');
            break;
          case 'projects':
            navigate('/school-admin/projects');
            break;
          case 'project-view':
            navigate('/school-admin/project-view');
            break;
          case 'logout':
            handleLogout();
            break;
          default:
            break;
        }
      };

      const handleLogout = () => {
        Cookies.remove('row-auth');
        localStorage.removeItem('token');
        navigate('/login');
      };

      return (
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={['dashboard']}
            style={{ height: '100%', borderRight: 0 }}
            onClick={handleMenuClick}
          >
            <Menu.Item icon={<DashboardOutlined />} key="dashboard">Dashboard</Menu.Item>
            <Menu.Item icon={<BookOutlined />} key="schools">Manage Schools</Menu.Item>
            <Menu.Item icon={<ProjectOutlined />} key="project-view">Project Tutorials</Menu.Item>
            <Menu.Item icon={<LogoutOutlined />} key="logout">Logout</Menu.Item>
          </Menu>
        </Sider>
      );
    };

    export default SchoolAdminSidebar;
