import React from "react";
import { List, Avatar } from "antd";

const ChatList = ({ chats, onSelectChat }) => {
    return (
        <List
            itemLayout="horizontal"
            dataSource={chats}
            renderItem={(chat) => (
                <List.Item
                    onClick={() => onSelectChat(chat)}
                    style={{
                        cursor: "pointer",
                        padding: "10px",
                        background: "#f5f5f5",
                        marginBottom: "5px",
                        borderRadius: "5px",
                    }}
                >
                    <List.Item.Meta
                        avatar={<Avatar src={`https://i.pravatar.cc/50?u=${chat.WaId}`} />}
                        title={chat.ProfileName || "Unknown"}
                        description={
                            chat.Messages && chat.Messages.length > 0
                                ? chat.Messages[chat.Messages.length - 1].Body
                                : "No messages"
                        }
                    />
                </List.Item>
            )}
        />
    );
};

export default ChatList;
