import React from 'react';
import { FaTruck, FaChalkboardTeacher, FaTools, FaTrophy } from 'react-icons/fa';

const cardData = [
    {
        icon: <FaTruck size={50} className="text-primary mx-auto" />,
        title: "Mobile Robotics Lab",
        description: "A fully-equipped van with robotics kits, coding tools, and interactive learning modules."
    },
    {
        icon: <FaChalkboardTeacher size={50} className="text-primary mx-auto" />,
        title: "Expert-Led Training",
        description: "Sessions conducted by experienced mentors and engineers."
    },
    {
        icon: <FaTools size={50} className="text-primary mx-auto" />,
        title: "Hands-on Learning",
        description: "Practical workshops on robotics, AI, and automation."
    },
    {
        icon: <FaTrophy size={50} className="text-primary mx-auto" />,
        title: "Competitions & Championships",
        description: "Training and mentoring students to compete at national and international levels."
    }
];

const Portfolio = () => {
    return (
        <div id="portfolio" className="bg-light py-12">
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-black uppercase font-bold">How It Works</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 text-lightBlack'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-light text-lightBlack">
                        Discover our approach to bringing robotics education to life.
                    </h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                        {cardData.map((card, index) => (
                            <div key={index} className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-black rounded-lg shadow-sm p-6 group">
                                <div className="m-2 text-center text-sm">
                                    {card.icon}
                                    <h2 className="font-normal my-4 text-xl text-center">{card.title}</h2>
                                    <p className="font-light">
                                        {card.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Portfolio;
