import axios from 'axios';
    import configs from "../../lib/config";

    const apiBaseUrl = configs.apiBaseUrl;

// Set up Axios to include the token in the headers of all requests
axios.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            const isValid = true; // Replace with actual token validation logic
            if (isValid) {
                config.headers.Authorization = `Bearer ${token}`;
            } else {
                localStorage.removeItem('token');
                window.location.href = '/portal/login'; // Redirect to login page
                throw new Error('Invalid token');
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Handle response errors
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Clear all user-related data from localStorage
            localStorage.clear();
            window.location.href = '/portal/login'; // Redirect to login page
        }
        return Promise.reject(error);
    }
);

    // AdminLogin admin
    export const loginAdmin = async (loginData) => {
      try {
        const response = await axios.post(`${apiBaseUrl}/auth/admin/login`, loginData);
        return response.data;
      } catch (error) {
        console.error('Error logging in admin:', error);
        throw error;
      }
    };

    // AdminLogin user
    export const loginUser = async (loginData) => {
      try {
        const response = await axios.post(`${apiBaseUrl}/auth/user/login`, loginData);
        return response.data;
      } catch (error) {
        console.error('Error logging in user:', error);
        throw error;
      }
    };
