import React from 'react';
import AdminLogin from "../components/auth/adminLogin";

function Auth() {
  return (
    <div className="App">
      <AdminLogin />
    </div>
  );
}

export default Auth;
