// src/components/auth/AdminLogin.js
import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { loginAdmin } from '../../context/auth/adminLogin';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

const onFinish = async (values) => {
  setLoading(true);
  try {
    const response = await loginAdmin(values);
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('userRole', response.data.role); // Save the user role in local storage

    message.success('Login successful!');
    navigate('/portal'); // Redirect to admin dashboard
  } catch (error) {
    message.error('Login failed. Please check your credentials.');
  } finally {
    setLoading(false);
  }
};
  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Card title="Admin Login" style={{ maxWidth: 400, width: '100%' }}>
          <Form
              name="login"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
          >
            <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Please input your Username!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
  );
};

export default AdminLogin;
