import axios from 'axios';
import configs from '../../lib/config';

const apiBaseUrl = configs.apiBaseUrl;
// Set up Axios to include the token in the headers of all requests
axios.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            const isValid = true; // Replace with actual token validation logic
            if (isValid) {
                config.headers.Authorization = `Bearer ${token}`;
            } else {
                localStorage.removeItem('token');
                window.location.href = '/portal/login'; // Redirect to login page
                throw new Error('Invalid token');
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


// API to get all Conversations
export const getConversations = async () => {
    try {
        const response = await axios.get(`${apiBaseUrl}/hook/conversations`);
        console.log(response);
        return response.data.data;
    } catch (error) {
        console.error('Error getting conversations:', error);
        throw error;
    }
};

// API to get Conversation by WaId

export const getConversationByWaId = async (waId) => {
    try {
        const response = await axios.get(`${apiBaseUrl}/hook/conversations/${waId}`);
        return response.data.data;
    } catch (error) {
        console.error('Error getting conversation by WaId:', error);
        throw error;
    }
}

// API to send Outbound Message
export const sendOutboundMessage = async (messageData) => {
    try {
        const response = await axios.post(`${apiBaseUrl}/hook/outbound`, messageData);
        return response.data.data;
    } catch (error) {
        console.error('Error sending outbound message:', error);
        throw error;
    }
};
