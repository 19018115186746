// src/components/school/ManageSchool.js
import React, { useEffect } from 'react';
import { Form, Input, Button, Select, Card, message } from 'antd';
import {updateSchool, updateVerificationStatus} from '../../context/dbTransactions/school';

const { Option } = Select;

const ManageSchool = ({ school, onClose }) => {
  const [formPhase] = Form.useForm();
  const [formAssociates] = Form.useForm();
  const [formVerification] = Form.useForm();

  useEffect(() => {
    if (school) {
      formPhase.setFieldsValue({ phaseStatus: school.phaseStatus });
      formAssociates.setFieldsValue({ associates: school.associates.join(', ') });
      formVerification.setFieldsValue({ verification_status: school.verification_status });
    }
  }, [school, formPhase, formAssociates, formVerification]);

  const onFinishPhase = async (values) => {
    try {
      await updateSchool(school.uid, values);
      message.success('Phase status updated successfully!');
      onClose();
    } catch (error) {
      message.error('Failed to update phase status.');
      console.error('Failed:', error);
    }
  };

  const onFinishAssociates = async (values) => {
    try {
      const associatesArray = values.associates.split(',').map(id => id.trim());
      await updateSchool(school.uid, { associates: associatesArray });
      message.success('Associates updated successfully!');
      onClose();
    } catch (error) {
      message.error('Failed to update associates.');
      console.error('Failed:', error);
    }
  };

  const onFinishVerification = async (values) => {
    try {
      await updateVerificationStatus(school.uid, values);
      message.success('Verification status updated successfully!');
      onClose();
    } catch (error) {
      message.error('Failed to update verification status.');
      console.error('Failed:', error);
    }
  };

  return (
      <Card title="Manage School" style={{ maxWidth: 600, margin: 'auto' }}>
        <Form
            form={formPhase}
            name="manage_phase"
            layout="vertical"
            onFinish={onFinishPhase}
        >
          <Form.Item
              label="Phase Status"
              name="phaseStatus"
              rules={[{ required: true, message: 'Please select the phase status!' }]}
          >
            <Select>
              <Option value="Foundation">Foundation</Option>
              <Option value="Intermediate">Intermediate</Option>
              <Option value="Advance">Advance</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Phase Status
            </Button>
          </Form.Item>
        </Form>

        <Form
            form={formAssociates}
            name="manage_associates"
            layout="vertical"
            onFinish={onFinishAssociates}
        >
          <Form.Item
              label="Associates"
              name="associates"
              rules={[{ required: true, message: 'Please input the associates!' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Associates
            </Button>
          </Form.Item>
        </Form>

        {school.verification_status !== 'approved' && (
            <Form
                form={formVerification}
                name="manage_verification"
                layout="vertical"
                onFinish={onFinishVerification}
            >
              <Form.Item
                  label="Verification Status"
                  name="verification_status"
                  rules={[{ required: true, message: 'Please select the verification status!' }]}
              >
                <Select>
                  <Option value="pending">Pending</Option>
                  <Option value="approved">Approved</Option>
                  <Option value="rejected">Rejected</Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Update Verification Status
                </Button>
              </Form.Item>
            </Form>
        )}
      </Card>
  );
};

export default ManageSchool;
