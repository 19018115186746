import React from 'react';
                                                import {  Row, Col, Card } from 'antd';
                                                import { Content } from 'antd/es/layout/layout';
                                                import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

                                                const data = [
                                                  { name: 'Approved', value: 7 },
                                                  { name: 'Pending', value: 2 },
                                                  { name: 'Rejected', value: 1 },
                                                ];

                                                const COLORS = ['#0088FE', '#FFBB28', '#FF8042'];

                                                const SchoolAdminDashboard = () => {
                                                  return (

                                                        <Content
                                                          className="site-layout-background"
                                                          style={{
                                                            padding: 24,
                                                            margin: 0,
                                                            minHeight: 280,
                                                          }}
                                                        >
                                                          <Row gutter={[16, 16]}>
                                                            <Col span={6}>
                                                              <Card title="School Phase Status" bordered={false}>
                                                                <p>Status: Active</p>
                                                              </Card>
                                                            </Col>
                                                            <Col span={6}>
                                                              <Card title="Total Projects" bordered={false}>
                                                                <p>Total: 10</p>
                                                              </Card>
                                                            </Col>
                                                            <Col span={6}>
                                                              <Card title="Total Van Visits Status" bordered={false}>
                                                                <p>Visits: 5</p>
                                                              </Card>
                                                            </Col>
                                                            <Col span={6}>
                                                              <Card title="Kit Delivery Status" bordered={false}>
                                                                <p>Delivered: Yes</p>
                                                              </Card>
                                                            </Col>
                                                          </Row>
                                                          <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
                                                            <Col span={24}>
                                                              <Card title="Project Submission Status" bordered={false}>
                                                                <PieChart width={400} height={400}>
                                                                  <Pie
                                                                    data={data}
                                                                    cx={200}
                                                                    cy={200}
                                                                    labelLine={false}
                                                                    label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                                                                    outerRadius={150}
                                                                    fill="#8884d8"
                                                                    dataKey="value"
                                                                  >
                                                                    {data.map((entry, index) => (
                                                                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                    ))}
                                                                  </Pie>
                                                                  <Tooltip />
                                                                  <Legend />
                                                                </PieChart>
                                                              </Card>
                                                            </Col>
                                                          </Row>
                                                        </Content>

                                                  );
                                                };

                                                export default SchoolAdminDashboard;
