// src/App.js
import React from 'react';
import './index.css';
import { Layout } from 'antd';
import { Route, Routes, useLocation } from 'react-router-dom';
import Topbar from './components/dashboard/Topbar';
import Sidebar from './components/dashboard/Sidebar';
import AppFooter from './components/dashboard/Footer';
import { Content } from 'antd/es/layout/layout';
import Dashboard from './pages/Dashboard';
import School from './pages/School';
import Projects from './pages/Projects';
import Admin from './pages/Admin';
import Auth from './pages/Auth';
import NotFound from './pages/NotFound';
import PrivateRoute from './components/PrivateRoute';
import LandingPage from './pages/LandingPage';
import Submissions from "./pages/Submissions";
import SchoolVerification from "./components/school/SchoolVerification";
import SchoolRegistrationPage from './pages/SchoolRegistrationPage';
import ManageAppUsers from "./pages/ManageAppUsers";
import SchoolLoginPage from './pages/SchoolLoginPage';
import SchoolAdminDashboard from './pages/SchoolAdminDashboard';
import ProjectView from './pages/ProjectView';
import SchoolAdminSidebar from './components/school-admin/SchoolAdminSidebar';
import ContactUs from "./pages/ContactUs";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import GetStart from "./pages/GetStart";
import Whatsapp from "./pages/Whatsapp";

function App() {
    const location = useLocation();
    const isLoginPage = location.pathname === '/portal/login';
    const isNotFoundPage = location.pathname === '/404';
    const isLandingPage = location.pathname === '/';
    const isPortalRoute = location.pathname.startsWith('/portal');
    const isRegistrationPage = location.pathname === '/school-registration';
    const isSchoolAdminRoute = location.pathname.startsWith('/school-admin');

    return (
        <div className="App">
            {isLandingPage ? (
                <Routes>
                    <Route exact path="/" element={<LandingPage />} />
                </Routes>
            ) : isPortalRoute ? (
                <Layout style={{ minHeight: '100vh' }}>
                    {!isLoginPage && <Topbar />}
                    <Layout>
                        {!isLoginPage && !isNotFoundPage && <Sidebar />}
                        <Layout style={{ padding: '0 24px 24px' }}>
                            <Content
                                className="site-layout-background"
                                style={{
                                    padding: 24,
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >
                                <Routes>
                                    <Route exact path="/portal/login" element={<Auth />} />
                                    <Route
                                        exact
                                        path="/portal"
                                        element={
                                            <PrivateRoute allowedRoles={['admin', 'superadmin', 'manager']}>
                                                <Dashboard />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/dashboard"
                                        element={
                                            <PrivateRoute allowedRoles={['admin', 'superadmin', 'manager']}>
                                                <Dashboard />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/school"
                                        element={
                                            <PrivateRoute allowedRoles={['admin', 'superadmin', 'manager']}>
                                                <School />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/projects"
                                        element={
                                            <PrivateRoute allowedRoles={['admin', 'superadmin', 'manager']}>
                                                <Projects />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/submission"
                                        element={
                                            <PrivateRoute allowedRoles={['admin', 'superadmin', 'manager']}>
                                                <Submissions />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/pending-schools"
                                        element={
                                            <PrivateRoute allowedRoles={['admin', 'superadmin', 'manager']}>
                                                <SchoolVerification />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/app-users"
                                        element={
                                            <PrivateRoute allowedRoles={['admin', 'superadmin', 'manager']}>
                                                <ManageAppUsers />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/conversation"
                                        element={
                                            <PrivateRoute allowedRoles={['admin', 'superadmin', 'manager']}>
                                                <Whatsapp />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/admins"
                                        element={
                                            <PrivateRoute allowedRoles={['admin', 'superadmin', 'manager']}>
                                                <Admin />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route path="/*" element={<NotFound />} />
                                </Routes>
                            </Content>
                            {!isLoginPage && <AppFooter />}
                        </Layout>
                    </Layout>
                </Layout>
            ) : isRegistrationPage ? (
                <Routes>
                    <Route exact path="/school-registration" element={<SchoolRegistrationPage />} />
                </Routes>
            ) : isSchoolAdminRoute ? (
                <Layout style={{ minHeight: '100vh' }}>
                    <SchoolAdminSidebar />
                    <Layout style={{ padding: '0 24px 24px' }}>
                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                            }}
                        >
                            <Routes>
                                <Route
                                    exact
                                    path="/school-admin"
                                    element={
                                        <PrivateRoute allowedRoles={['principal']}>
                                            <SchoolAdminDashboard />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact
                                    path="/"
                                    element={
                                        <PrivateRoute allowedRoles={['principal']}>
                                            <SchoolAdminDashboard />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact
                                    path="/school-admin/project-view"
                                    element={
                                        <PrivateRoute allowedRoles={['principal']}>
                                            <ProjectView />
                                        </PrivateRoute>
                                    }
                                />

                                {/* Add more School Admin routes here */}

                                <Route path="/*" element={<NotFound />} />

                            </Routes>

                        </Content>
                    </Layout>
                </Layout>

            ) : (
                <Routes>
                    <Route exact path="/login" element={<SchoolLoginPage />} />
                    <Route exact path="/contact" element={<ContactUs />} />
                    <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route exact path="/terms-service" element={<TermsOfService />} />
                    <Route exact path="/get-start" element={<GetStart />} />

                    <Route path="/*" element={<NotFound />} />
                </Routes>
            )}
        </div>
    );
}

export default App;
