import axios from 'axios';
import appConfig from "../../lib/config";
// Set up Axios to include the token in the headers of all requests
axios.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            const isValid = true; // Replace with actual token validation logic
            if (isValid) {
                config.headers.Authorization = `Bearer ${token}`;
            } else {
                localStorage.removeItem('token');
                window.location.href = '/portal/login'; // Redirect to login page
                throw new Error('Invalid token');
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Handle response errors
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Clear all user-related data from localStorage
            localStorage.clear();
            window.location.href = '/portal/login'; // Redirect to login page
        }
        return Promise.reject(error);
    }
);
const workerURL = `${appConfig.workerURL}/ROBONIST_BUCKET`;
export async function deleteStorageFile(folderName, fileKey) {
    try {
        const response = await axios.delete(`${workerURL}/${folderName}/${fileKey}`);
        console.log(`File ${fileKey} deleted successfully from folder ${folderName}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting storage file:', error.response?.data || error.message);
        throw error;
    }
}

export async function updateStorageFile(folderName, fileKey, fileBuffer, fileType) {
    const formData = new FormData();
    const blob = new Blob([fileBuffer], { type: fileType });
    formData.append('file', blob, fileKey);
    formData.append('folder', folderName);
    try {
        const response = await axios.post(`${workerURL}/${fileKey}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log(`File ${fileKey} updated successfully in folder ${folderName}`);
        return response.data;
    } catch (error) {
        console.error('Error updating storage file:', error.response?.data || error.message);
        throw error;
    }
}

export async function getStorageFile(folderName, fileKey) {
    try {
        const response = await axios.get(`${workerURL}/${folderName}/${fileKey}`, {
            responseType: 'arraybuffer', // for handling binary data
        });
        console.log(`File ${fileKey} retrieved successfully from folder ${folderName}`);
        return response.data;
    } catch (error) {
        console.error('Error retrieving storage file:', error.response?.data || error.message);
        throw error;
    }
}

export async function listStorageFilesInFolder(folderName) {
    try {
        const response = await axios.get(`${workerURL}/${folderName}`);
        console.log(`Files in folder ${folderName} listed successfully`);
        return response.data;
    } catch (error) {
        console.error('Error listing files in folder:', error.response?.data || error.message);
        throw error;
    }
}

export async function listAllStorageFiles() {
    try {
        const response = await axios.get(workerURL);
        console.log('All files listed successfully');
        return response.data;
    } catch (error) {
        console.error('Error listing all files:', error.response?.data || error.message);
        throw error;
    }
}
