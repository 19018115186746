import React from 'react';
                                        import { Link } from 'react-router-dom';
                                        import { HashLink } from 'react-router-hash-link';

                                        const Footer = () => {
                                            return (
                                                <>
                                                    <footer className="bg-light border-t border-gray-200 py-12">
                                                        <div className="max-w-7xl mx-auto px-4 sm:px-6">
                                                            <div className="grid sm:grid-cols-12 gap-12 py-8 md:py-12">

                                                                {/* 1st block */}
                                                                <div className="col-span-12 lg:col-span-4">
                                                                    <img src="/assets/logo.png" alt="Logo" style={{height:' 120px'}}/>
                                                                </div>

                                                                {/* 2nd block */}
                                                                {/*<div className="col-span-6 md:col-span-3 lg:col-span-2">*/}
                                                                {/*    <h6 className="text-lightblack text-xl font-bold mb-4">LINKS</h6>*/}
                                                                {/*    <ul className="text-md">*/}
                                                                {/*        <li className="mb-2">*/}
                                                                {/*            <HashLink to="/#about" className="text-lightblack hover:text-gray-900 transition duration-250 ease-in-out">About Us</HashLink>*/}
                                                                {/*        </li>*/}
                                                                {/*        <li className="mb-2">*/}
                                                                {/*            <HashLink to="/#services" className="text-lightblack hover:text-gray-900 transition duration-250 ease-in-out">Services</HashLink>*/}
                                                                {/*        </li>*/}
                                                                {/*        <li className="mb-2">*/}
                                                                {/*            <HashLink to="/#contact" className="text-lightblack hover:text-gray-900 transition duration-250 ease-in-out">Contact</HashLink>*/}
                                                                {/*        </li>*/}
                                                                {/*        <li className="mb-2">*/}
                                                                {/*            <HashLink to="/#privacy-policy" className="text-lightblack hover:text-gray-900 transition duration-250 ease-in-out">Privacy Policy</HashLink>*/}
                                                                {/*        </li>*/}
                                                                {/*        <li className="mb-2">*/}
                                                                {/*            <HashLink to="/#terms-of-service" className="text-lightblack hover:text-gray-900 transition duration-250 ease-in-out">Terms of Service</HashLink>*/}
                                                                {/*        </li>*/}
                                                                {/*    </ul>*/}
                                                                {/*</div>*/}

                                                                <div className="col-span-6 md:col-span-3 lg:col-span-4">
                                                                    <h6 className="text-lightblack text-xl font-bold mb-4">Company
                                                                        </h6>
                                                                    <ul className="text-md">
                                                                        <li className="mb-2">
                                                                            <HashLink to="/#about"
                                                                                      className="text-lightblack hover:text-gray-900 transition duration-250 ease-in-out">About
                                                                                Us</HashLink>
                                                                        </li>
                                                                        <li className="mb-2">
                                                                            <HashLink to="/#services"
                                                                                      className="text-lightblack hover:text-gray-900 transition duration-250 ease-in-out">Our Mission
                                                                            </HashLink>
                                                                        </li>
                                                                        <li className="mb-2">
                                                                            <HashLink to="/contact"
                                                                                      className="text-lightblack hover:text-gray-900 transition duration-250 ease-in-out">Contact</HashLink>
                                                                        </li>
                                                                        <li className="mb-2">
                                                                            <HashLink to="/privacy-policy"
                                                                                      className="text-lightblack hover:text-gray-900 transition duration-250 ease-in-out">Privacy
                                                                                Policy</HashLink>
                                                                        </li>
                                                                        <li className="mb-2">
                                                                            <HashLink to="/terms-service"
                                                                                      className="text-lightblack hover:text-gray-900 transition duration-250 ease-in-out">Terms
                                                                                of Service</HashLink>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                {/* 4th block */}
                                                                <div className="col-span-12 lg:col-span-2 text-center">
                                                                    <h6 className="text-lightblack text-xl font-bold mb-4">FOLLOW
                                                                        US</h6>
                                                                    <div className="flex justify-center space-x-4">
                                                                        <Link to="https://www.instagram.com/roboticsonwheels/"
                                                                              className="text-lightblack hover:text-gray-500 transition duration-150 ease-in-out">
                                                                            <svg className="w-8 h-8 fill-current"
                                                                                 viewBox="0 0 32 32"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M16 3c-3.3 0-3.7 0-5 .1-1.3.1-2.2.3-3 .6-.8.3-1.5.7-2.2 1.4-.7.7-1.1 1.4-1.4 2.2-.3.8-.5 1.7-.6 3C3 12.3 3 12.7 3 16s0 3.7.1 5c.1 1.3.3 2.2.6 3 .3.8.7 1.5 1.4 2.2.7.7 1.4 1.1 2.2 1.4.8.3 1.7.5 3 .6 1.3.1 1.7.1 5 .1s3.7 0 5-.1c1.3-.1 2.2-.3 3-.6.8-.3 1.5-.7 2.2-1.4.7-.7 1.1-1.4 1.4-2.2.3-.8.5-1.7.6-3 .1-1.3.1-1.7.1-5s0-3.7-.1-5c-.1-1.3-.3-2.2-.6-3-.3-.8-.7-1.5-1.4-2.2-.7-.7-1.4-1.1-2.2-1.4-.8-.3-1.7-.5-3-.6C19.7 3 19.3 3 16 3zm0 2c3.2 0 3.6 0 4.9.1 1.2.1 1.8.2 2.2.4.5.2.9.4 1.3.8.4.4.6.8.8 1.3.2.4.3 1 .4 2.2.1 1.3.1 1.7.1 4.9s0 3.6-.1 4.9c-.1 1.2-.2 1.8-.4 2.2-.2.5-.4.9-.8 1.3-.4.4-.8.6-1.3.8-.4.2-1 .3-2.2.4-1.3.1-1.7.1-4.9.1s-3.6 0-4.9-.1c-1.2-.1-1.8-.2-2.2-.4-.5-.2-.9-.4-1.3-.8-.4-.4-.6-.8-.8-1.3-.2-.4-.3-1-.4-2.2-.1-1.3-.1-1.7-.1-4.9s0-3.6.1-4.9c.1-1.2.2-1.8.4-2.2.2-.5.4-.9.8-1.3.4-.4.8-.6 1.3-.8.4-.2 1-.3 2.2-.4C12.4 5 12.8 5 16 5zm0 3c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm0 2c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4zm6.5-2.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5z"/>
                                                                            </svg>
                                                                        </Link>
                                                                        <Link to="https://www.facebook.com/people/Robotics-On-Wheels/61573373948309/"
                                                                              className="text-lightblack hover:text-gray-500 transition duration-150 ease-in-out">
                                                                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z"/>
                                                                            </svg>
                                                                        </Link>
                                                                    </div>                                                                </div>
                                                            </div>

                                                            <div className="flex flex-wrap items-center justify-center md:justify-between py-4 border-t border-gray-200">
                                                                <div className="w-full md:w-auto text-center text-sm text-gray-600">
                                                                    &copy; {new Date().getFullYear()} Robonist Tech Solutions Private Limited
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </footer>
                                                </>
                                            );
                                        }

                                        export default Footer;
