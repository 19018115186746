import React, { useState, useEffect } from 'react';
          import { Form, Input, Button, Select, Card, message, Upload } from 'antd';
          import { UploadOutlined } from '@ant-design/icons';
          import { getCities, getStates } from "../../utils/StateCitydataUtils";
          import { createSchool, updateSchool } from "../../context/dbTransactions/school";
import {updateStorageFile} from "../../context/dbTransactions/storage";
import TextArea from "antd/es/input/TextArea";

          const { Option } = Select;

          const states = getStates();
          const cities = getCities();

          const selectOptions = {
            coEducational: ["Yes", "Boys Only", "Girls Only"],
            highestQualification: ["PhD", "Post Graduate", "Graduate", "Others"],
            streamQualification: ["Science (PCB)", "Commerce", "Arts", "Maths", "Computer Science", "Others"],
            boardAffiliation: ["CBSE", "ICSE / ISC", "State Board"],
            schoolType: ["Central Government (CBSE)", "State Government", "Local Body (Municipality / Nagar Nigam)", "Private Aided", "Private Unaided", "Others"]
          };

          const RegisterSchools = ({ school }) => {
            const [form] = Form.useForm();
            const [selectedState, setSelectedState] = useState(school ? school.school_state : '');
            const [cityOptions, setCityOptions] = useState([]);
            const [fileList, setFileList] = useState([]);

            useEffect(() => {
              setCityOptions(selectedState ? cities[selectedState] || [] : []);
            }, [selectedState]);

            useEffect(() => {
              if (school) {
                form.setFieldsValue(school);
                if (school.school_logo) {
                  setFileList([
                    {
                      uid: '-1',
                      name: 'school_logo.png',
                      status: 'done',
                      url: school.school_logo,
                    },
                  ]);
                }
              }
            }, [school, form]);

            const handleUpload = async ({ file }) => {
              const folderName = 'school_logos';
              const fileKey = file.name;
              const fileType = file.type;

              try {
                const fileBuffer = await file.arrayBuffer();
                const data = await updateStorageFile(folderName, fileKey, fileBuffer, fileType);
                form.setFieldsValue({ school_logo: data.publicUrl });
                message.success(`${file.name} uploaded successfully`);
              } catch (error) {
                message.error(`Failed to upload ${file.name}`);
              }
            };

            const onFinish = async (values) => {
              try {
                if (school) {
                  await updateSchool(school._id, values);
                  message.success('School updated successfully!');
                } else {
                  await createSchool(values);
                  message.success('School registered successfully!');
                }
                form.resetFields();
                window.location.reload();
              } catch (error) {
                message.error('Failed to submit school.');
                console.error('Failed:', error);
              }
            };

            const onFinishFailed = (errorInfo) => {
              console.error('Failed:', errorInfo);
            };

            return (
              <Card title="Update School Details" style={{ maxWidth: 600, margin: 'auto' }}>
                <Form
                  form={form}
                  name="register_school"
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  initialValues={{ verification_status: 'approved' }}
                >
                  <Form.Item
                    label="School Name"
                    name="school_name"
                    rules={[{ required: true, message: 'Please input the school name!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="School UDIAS"
                    name="school_udias"
                    rules={[{ required: true, message: 'Please input the school UDIAS!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="School Address"
                    name="school_address"
                    rules={[{ required: true, message: 'Please input the school address!' }]}
                  >
                    <TextArea />
                  </Form.Item>

                  <Form.Item
                    label="School State"
                    name="school_state"
                    rules={[{ required: true, message: 'Please select the school state!' }]}
                  >
                    <Select onChange={(value) => setSelectedState(value)}>
                      {states.map((state) => (
                        <Option key={state.code} value={state.value}>
                          {state.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item label="District" name="school_city" rules={[{ required: true, message: 'Please select the city!' }]}>
                    <Select disabled={!selectedState}>
                      {cityOptions.map((city) => (
                          <Option key={city} value={city}>
                            {city}
                          </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Sub - District" name="school_district" rules={[{ required: true, message: 'Please enter the district!' }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Pin / Zip Code"
                    name="pin_zip_code"
                    rules={[{ required: true, message: 'Please input the pin/zip code!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Name of the Principal"
                    name="principal_name"
                    rules={[{ required: true, message: 'Please input the principal\'s name!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Email Address"
                    name="principal_email"
                    rules={[{ required: true, message: 'Please input the email address!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Phone Number"
                    name="principal_phone"
                    rules={[{ required: true, message: 'Please input the phone number!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Highest Qualification of Principal"
                    name="principal_highest_qualification"
                    rules={[{ required: true, message: 'Please select the highest qualification!' }]}
                  >
                    <Select>
                      {selectOptions.highestQualification.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Stream Qualification of Principal"
                    name="principal_stream_qualification"
                    rules={[{ required: true, message: 'Please select the stream qualification!' }]}
                  >
                    <Select>
                      {selectOptions.streamQualification.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Year Since Establishment"
                    name="year_establishment"
                    rules={[{ required: true, message: 'Please input the year since establishment!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Type of School"
                    name="school_type"
                    rules={[{ required: false, message: 'Please select the type of school!' }]}
                  >
                    <Select>
                      {selectOptions.schoolType.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Board of Affiliation"
                    name="board_affiliation"
                    rules={[{ required: true, message: 'Please select the board of affiliation!' }]}
                  >
                    <Select>
                      {selectOptions.boardAffiliation.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Co Educational?"
                    name="co_educational"
                    rules={[{ required: true, message: 'Please select the co-educational status!' }]}
                  >
                    <Select>
                      {selectOptions.coEducational.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Total Number of Teachers"
                    name="total_teachers"
                    rules={[{ required: true, message: 'Please input the total number of teachers!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Total Number of Students"
                    name="total_students"
                    rules={[{ required: true, message: 'Please input the total number of students!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Total Student Enrollment (VI to X)"
                    name="total_enrollment_vi_to_x"
                    rules={[{ required: true, message: 'Please input the total student enrollment!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="School Logo"
                    name="school_logo"
                    rules={[{ required: false, message: 'Please upload the school logo!' }]}
                  >
                    <Upload
                      name="logo"
                      listType="picture"
                      maxCount={1}
                      fileList={fileList}
                      beforeUpload={(file) => {
                        setFileList([file]);
                        handleUpload({ file });
                        return false; // Prevent automatic upload
                      }}
                      onRemove={() => setFileList([])}
                    >
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                  </Form.Item>

                  <Form.Item
                    name="verification_status"
                    initialValue="approved"
                    hidden
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Update
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            );
          };

          export default RegisterSchools;
