import axios from 'axios';
import configs from "../../lib/config";

const apiBaseUrl = configs.apiBaseUrl;
// Set up Axios to include the token in the headers of all requests
axios.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            const isValid = true; // Replace with actual token validation logic
            if (isValid) {
                config.headers.Authorization = `Bearer ${token}`;
            } else {
                localStorage.removeItem('token');
                window.location.href = '/portal/login'; // Redirect to login page
                throw new Error('Invalid token');
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Handle response errors
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Clear all user-related data from localStorage
            localStorage.clear();
            window.location.href = '/portal/login'; // Redirect to login page
        }
        return Promise.reject(error);
    }
);
// Create a new school
export const createSchool = async (schoolData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/school`, schoolData);
    return response.data;
  } catch (error) {
    console.error('Error creating school:', error);
    throw error;
  }
};

export const loginSchool = async (loginData) => {
    try {
        const response = await axios.post(`${apiBaseUrl}/school/login`, loginData);
        return response.data;
    } catch (error) {
        console.error('Error logging in school:', error);
        throw error;
    }
}


// Get all schools
export const getSchools = async () => {
  try {
    const response = await axios.get(`${apiBaseUrl}/school`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching schools:', error);
    throw error;
  }
};

// Get school by ID
export const getSchoolById = async (schoolId) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/school/${schoolId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching school by ID:', error);
    throw error;
  }
};

// Update school by ID
export const updateSchool = async (schoolId, schoolData) => {
  try {
    const response = await axios.put(`${apiBaseUrl}/school/${schoolId}`, schoolData);
    return response.data;
  } catch (error) {
    console.error('Error updating school:', error);
    throw error;
  }
};

// Delete school by ID
export const deleteSchool = async (schoolId) => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/school/${schoolId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting school:', error);
    throw error;
  }
};


// Update verification status of a school by ID
export const updateVerificationStatus = async (schoolId, verification_status, reason) => {
  try {
    const response = await axios.put(`${apiBaseUrl}/school/${schoolId}/verification-status`, { verification_status, reason });
    return response.data;
  } catch (error) {
    console.error('Error updating verification status:', error);
    throw error;
  }
};


export const sendPasswordEmailByAdmin = async (email, phone) => {
    try {
        const response = await axios.post(`${apiBaseUrl}/school/send-password`, { email  , phone});
        return response.data;
    } catch (error) {
        console.error('Error sending password email:', error);
        throw error;
    }
}

export const sendHTMLEmail = async (emailData) => {
    try {
        const response = await axios.post(`${apiBaseUrl}/school/send-email`, emailData);
        return response.data;
    } catch (error) {
        console.error('Error sending HTML email:', error);
        throw error;
    }
}


export const sendEmailFromAdmin = async (emailData) => {
    try {
        const response = await axios.post(`${apiBaseUrl}/school/send-admin-emails`, emailData);
        return response.data;
    } catch (error) {
        console.error('Error sending email from admin:', error);
        throw error;
    }
}
