import axios from 'axios';
import configs from '../../lib/config';

const apiBaseUrl = configs.apiBaseUrl;
// Set up Axios to include the token in the headers of all requests
axios.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        const isValid = true; // Replace with actual token validation logic
        if (isValid) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          localStorage.removeItem('token');
          window.location.href = '/portal/login'; // Redirect to login page
          throw new Error('Invalid token');
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

// Handle response errors
axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Clear all user-related data from localStorage
        localStorage.clear();
        window.location.href = '/portal/login'; // Redirect to login page
      }
      return Promise.reject(error);
    }
);
export const getAnalyticsData = async () => {
  try {
    const response = await axios.get(`${apiBaseUrl}/analytics`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching analytics data:', error);
    throw error;
  }
};
