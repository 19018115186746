import React, { useState, useEffect } from "react";
import { Card, Input, Button } from "antd";
import { sendOutboundMessage } from "../../context/dbTransactions/conversations";

const ChatBox = ({ selectedChat }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");

    // Update messages when selectedChat changes
    useEffect(() => {
        if (selectedChat && Array.isArray(selectedChat.Messages)) {
            setMessages(selectedChat.Messages);
        } else {
            setMessages([]); // Reset messages if no chat is selected
        }
    }, [selectedChat]);

    const handleSend = async () => {
        if (!input.trim() || !selectedChat) return;

        const newMessage = {
            conversationId: selectedChat._id,
            body: input,
            from: "You",
            to: `whatsapp:+${selectedChat.WaId}`,
            timestamp: new Date().toISOString(),
        };

        try {
            const createdMessage = await sendOutboundMessage(newMessage);

            if (createdMessage && createdMessage.Body) {
                setMessages((prevMessages) => [...prevMessages, createdMessage]);
            } else {
                console.error("Invalid response from sendOutboundMessage:", createdMessage);
            }

            setInput("");
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    // Display a message if no chat is selected
    if (!selectedChat) {
        return <p style={{ padding: "20px" }}>Select a chat to start a conversation</p>;
    }

    return (
        <Card
            title={`Chat with ${selectedChat.ProfileName || "Unknown"}`}
            style={{
                width: "100%",
                height: "500px",
                display: "flex",
                flexDirection: "column",
            }}
        >
            {/* Chat messages container */}
            <div
                style={{
                    flex: 1,
                    overflowY: "auto",
                    paddingBottom: "10px",
                    height: "400px",
                }}
            >
                {messages.map((msg, index) => (
                    <p key={index}>
                        <strong>{msg.From && msg.From.includes("whatsapp:") ? "Them" : "You"}:</strong> {msg.Body}
                    </p>
                ))}
            </div>

            {/* Message input field */}
            <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type a message..."
                onPressEnter={handleSend}
                addonAfter={<Button onClick={handleSend}>Send</Button>}
            />
        </Card>
    );
};

export default ChatBox;
