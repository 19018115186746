// src/pages/ProjectView.js
                      import React, { useState, useEffect } from 'react';
                      import { Card, Table, Button, message, Modal, Input } from 'antd';
                      import { PlayCircleOutlined } from '@ant-design/icons';
                      import { getProjects } from '../context/dbTransactions/projects';

                      const { Search } = Input;

                      const extractYouTubeVideoId = (url) => {
                        const regex = /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
                        const match = url.match(regex);
                        return match ? match[1] : null;
                      };

                      const ProjectView = () => {
                        const [projects, setProjects] = useState([]);
                        const [filteredProjects, setFilteredProjects] = useState([]);
                        const [videoModalVisible, setVideoModalVisible] = useState(false);
                        const [currentVideoUrl, setCurrentVideoUrl] = useState('');

                        useEffect(() => {
                          fetchProjects();
                        }, []);

                        const fetchProjects = async () => {
                          try {
                            const data = await getProjects();
                            setProjects(data);
                            setFilteredProjects(data);
                          } catch (error) {
                            message.error('Failed to fetch projects.');
                          }
                        };

                        const handlePlayVideo = (videoUrl) => {
                          const videoId = extractYouTubeVideoId(videoUrl);
                          if (videoId) {
                            const embedUrl = `https://www.youtube.com/embed/${videoId}`;
                            setCurrentVideoUrl(embedUrl);
                            setVideoModalVisible(true);
                          } else {
                            message.error('Invalid YouTube URL.');
                          }
                        };
                          // eslint-disable-next-line no-unused-vars
                        const handleDownloadPdf = (pdfUrl) => {
                          const link = document.createElement('a');
                          link.href = pdfUrl;
                          link.download = 'project.pdf';
                          link.click();
                        };

                        const handleSearch = (value) => {
                          const filtered = projects.filter(project =>
                            project.name.toLowerCase().includes(value.toLowerCase()) ||
                            project.code.toLowerCase().includes(value.toLowerCase()) ||
                            project.phase.toLowerCase().includes(value.toLowerCase())
                          );
                          setFilteredProjects(filtered);
                        };

                        const columns = [
                          {
                            title: 'Project Thumbnail',
                            dataIndex: 'projectThumbnail',
                            key: 'projectThumbnail',
                            render: (text) => <img src={text} alt="Project Thumbnail" style={{ maxWidth: '100px' }} />,
                          },
                          {
                            title: 'Name',
                            dataIndex: 'name',
                            key: 'name',
                          },
                          {
                            title: 'Duration',
                            dataIndex: 'duration',
                            key: 'duration',
                          },
{
  title: 'Phase',
  dataIndex: 'phase',
  key: 'phase',
    filters: [
        { text: 'Foundation', value: '1' },
        { text: 'Intermediate', value: '2' },
        { text: 'Advance', value: '3' },
    ],
    onFilter: (value, record) => record.phase.includes(value),
  render: (text) => {
    const phase = Number(text);
    switch (phase) {
      case 1:
        return 'Foundation';
      case 2:
        return 'Intermediate';
      case 3:
        return 'Advance';
      default:
        return text;
    }
  },
},
                            {
                                title: 'Code',
                                dataIndex: 'code',
                                key: 'code',
                                filters: [
                                    { text: 'Foundation', value: 'FP' },
                                    { text: 'Intermediate', value: 'IP' },
                                    { text: 'Advance', value: 'AP' },
                                ],
                                onFilter: (value, record) => record.code.includes(value),
                                defaultSortOrder: 'ascend',
                                sorter: (a, b) => {
                                    const numA = parseInt(a.code.split('-').pop(), 10);
                                    const numB = parseInt(b.code.split('-').pop(), 10);
                                    return numA - numB;
                                },
                            },

                          {
                            title: 'Actions',
                            key: 'actions',
                            render: (text, record) => (
                              <span>
                                <Button icon={<PlayCircleOutlined />} style={{ marginRight: 8 }} onClick={() => handlePlayVideo(record.projectVideoLink)} />
                                {/*<Button icon={<DownloadOutlined />} onClick={() => handleDownloadPdf(record.pdfUrl)} />*/}
                              </span>
                            ),
                          },
                        ];

                        return (
                          <div>
                            <Card title="Projects">
                              <Search
                                placeholder="Search by name, code, or phase"
                                onSearch={handleSearch}
                                style={{ marginBottom: 16 }}
                              />
                              <Table columns={columns} dataSource={filteredProjects} rowKey="_id" />
                            </Card>
                            <Modal
                              title="Project Video"
                              visible={videoModalVisible}
                              onCancel={() => setVideoModalVisible(false)}
                              footer={null}
                              width={800}
                            >
                              <iframe
                                title="Project Video"
                                width="100%"
                                height="450"
                                src={currentVideoUrl}
                                frameBorder="0"
                                allowFullScreen
                              ></iframe>
                            </Modal>
                          </div>
                        );
                      };

                      export default ProjectView;
