import React from 'react';
import Footer from "../components/landing/Footer";
import NavBar from "../components/landing/Navbar/NavBar";

const TermsOfService = () => {
    return (
        <div className="bg-light py-12">
            <div>
                <NavBar />
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-bold text-center text-primary mb-8">Terms of Service</h2>
                <div className="bg-white p-8 rounded-lg shadow-md">
                    <h1 className="text-3xl font-bold mt-10 mb-6">Terms & Conditions</h1>

                    <section className="mb-6">
                        <h2 className="text-xl font-semibold">1. Introduction</h2>
                        <p>These Terms & Conditions govern the use of Robonist's services, including robotics training,
                            STEM kits, and educational programs. By accessing our services, you agree to comply with
                            these terms.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-xl font-semibold">2. Services</h2>
                        <p>We provide robotics lab development, training, and government-backed STEM education programs.
                            Our services are for educational purposes and must not be misused.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-xl font-semibold">3. User Responsibilities</h2>
                        <ul className="list-disc pl-5">
                            <li>Users must provide accurate information for enrollment and training.</li>
                            <li>Schools and participants must adhere to safety guidelines during training sessions.</li>
                            <li>Unauthorized reproduction or misuse of our content and training materials is
                                prohibited.
                            </li>
                        </ul>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-xl font-semibold">4. Payments & Refunds</h2>
                        <p>Payment terms will be specified in agreements with schools or institutions. Refunds, if
                            applicable, will be handled on a case-by-case basis.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-xl font-semibold">5. Limitation of Liability</h2>
                        <p>Robonist is not responsible for indirect damages arising from the use of our services, except
                            in cases of gross negligence or misconduct.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-xl font-semibold">6. Intellectual Property</h2>
                        <p>All training materials, software, and content developed by Robonist remain our intellectual
                            property and may not be copied or distributed without permission.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-xl font-semibold">7. Amendments</h2>
                        <p>We reserve the right to modify these terms and will notify users of significant changes.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-xl font-semibold">8. Contact Information</h2>
                        <p>For any questions regarding our Privacy Policy or Terms & Conditions, please contact us
                            at <strong>+91 9272127556 / info@roboticsonwheels.in</strong>.</p>

                    </section>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default TermsOfService;
